import { MediaMutableMetadata } from './admin/features/medias/slice';

interface DBObject {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface KustomTranslatedString {
  fr: string;
  en?: string;
  es?: string;
  de?: string;
  ca?: string;
  it?: string;
  pt?: string;
  ru?: string;
}

export interface RecruitmentCity {
  id: string;
  name: string;
}

export interface Establishment {
  _id: string;
  name: string;
  mediaTags?: string[];
  storage: number;
  storageLimit: number;
  recruitmentCities?: RecruitmentCity[];
}

export interface KustomUser {
  _id: string;
  email: string;
}

export type NewsAction =
  | 'LINK_TO_BE'
  | 'LINK_EXTERNAL'
  | 'LINK_INTERNAL'
  | 'EMAIL';

export type PublishLocation =
  | 'OFFERS_NEWS_PAGE'
  | 'VIP_OFFERS_PAGE'
  | 'SPOTLIGHT_OFFER'
  | 'HSP_MODULE';

export type NewsSocialMedia = 'FACEBOOK' | 'INSTAGRAM';

export type Rubric =
  | 'STAY'
  | 'RESTAURANT'
  | 'SPA'
  | 'SEMINARS'
  | 'WEDDINGS'
  | 'NONE'
  | 'WINTER'
  | 'SUMMER'
  | 'AUTUMN'
  | 'SPRING';

export type Theme =
  | 'LAST_MINUTE'
  | 'EARLY_BOOKING'
  | 'PROMOTION'
  | 'PACKAGE'
  | 'GIFT_BOX'
  | 'NONE';

export interface News extends DBObject, PlanificationModel {
  title?: KustomTranslatedString;
  shortDescription?: KustomTranslatedString;
  description?: KustomTranslatedString;
  medias?: KustomMedia[];
  status: OffersNewsStatus | string;
  isButtonDisabled: boolean;
  buttonLabel?: string;
  buttonAction?: NewsAction;
  buttonActionString: string;
  establishmentsIds: string[];
  location: PublishLocation[];
  socialMedias: NewsSocialMedia[];
  isModel: boolean;
  isSuspended: boolean;
  extraData?: ExtraData;
  objectType?: string;
  prettyURL?: KustomTranslatedString;
}

export type DiscountDisplay = 'REGULAR' | 'WITH_PERCENTAGE';

export type FlashSaleTimeUnit = 'HOURS' | 'DAYS' | 'WEEKS' | 'MONTHS';

export type ValidityPeriodModel = 'FROMTO' | 'FROM' | 'TO';

export type ActivePeriodModel = 'ALWAYS' | 'SET_DATES';

export type ActivePeriodSetDatesModel = 'FROM' | 'FROMTO' | 'DAYS';

export type ActivePeriodDayTimeSlot = { from: Date; to: Date };

export type ActivePeriodDay = {
  dayIndex: number;
  timeSlots: ActivePeriodDayTimeSlot[];
};

export const offersNewsStatusValues = [
  { value: 'EXPIRED', label: 'Expirée' },
  { value: 'ACTIVE', label: 'En ligne' },
  { value: 'PENDING', label: 'Programmé' },
  { value: 'SUSPENDED', label: 'Suspendu' },
  { value: 'DRAFT', label: 'Brouillon' },
];
export type OffersNewsStatus =
  | 'EXPIRED'
  | 'ACTIVE'
  | 'PENDING'
  | 'SUSPENDED'
  | 'DRAFT';
interface ExtraData {
  activeDays: number;
  reservations?: number;
  sales?: number;
  status: OffersNewsStatus;
}
export interface Offer extends DBObject, PlanificationModel {
  title?: KustomTranslatedString;
  shortDescription?: KustomTranslatedString;
  description?: KustomTranslatedString;
  medias?: KustomMedia[];
  status: OffersNewsStatus | string;
  rubrics: Rubric[];
  theme: Theme | string;
  isButtonDisabled: boolean;
  buttonLabel?: string;
  buttonAction?: NewsAction;
  buttonActionString: string;
  isPriceDisabled: boolean;
  isPriceFrom: boolean;
  price: number;
  priceDetails: KustomTranslatedString;
  isDiscountEnabled: boolean;
  discount: number;
  isDiscountPercentage: boolean;
  discountDisplay: DiscountDisplay;
  isFlashSaleEnabled: boolean;
  flashSaleTime: number;
  flashSaleTimeUnit: FlashSaleTimeUnit;
  flashSaleLocation: PublishLocation[];
  metaTitle: string;
  metaDescription: string;
  prettyURL: KustomTranslatedString;
  validityPeriodModel: ValidityPeriodModel;
  validityPeriodFrom: Date | string;
  validityPeriodTo: Date | string;
  activePeriodModel: ActivePeriodModel;
  activePeriodSetDatesModel: ActivePeriodSetDatesModel;
  activePeriodFrom: Date | string;
  activePeriodTo: Date | string;
  activePeriodDays: ActivePeriodDay[];
  establishmentsIds: string[];
  location: PublishLocation[];
  socialMedias: NewsSocialMedia[];
  isModel: boolean;
  isSuspended: boolean;
  extraData?: ExtraData;
  objectType?: string;
}

export interface KustomPageComponent<T> {
  id: string;
  type: string;
  nameAs?: string;
  isLocked: boolean;
  data: T;
}

interface PlanificationModel {
  objectCode: string;
}

export interface KustomPage extends DBObject, PlanificationModel {
  pageCode: string;
  index: number;
  isCopy: boolean;
  title: KustomTranslatedString;
  model?: string;
  category: string;
  stringBeforeSlug: KustomTranslatedString;
  titleH1?: KustomTranslatedString;
  targetLink?: string;
  excludeFromMenu: boolean;
  cssClass?: string;
  metaTitle?: KustomTranslatedString;
  prettyUrl: KustomTranslatedString;
  metaDescription: KustomTranslatedString;
  status: string;
  publishedOn?: Date;
  SEOScore?: number;
  components: KustomPageComponent<any>[];
}

export interface KustomRoutes {
  [route: string]: KustomPage;
}

export interface MediaMetadata {
  _id: string;
  bucketName: string;
  keys?: KustomTranslatedString;
  titles?: KustomTranslatedString;
  alts?: KustomTranslatedString;
  key: string;
  originalKey: string;
  tags: string[];
  autoplay?: string;
  width?: number;
  height?: number;
  size?: number;
  duration?: string;
  url?: string;
  description?: string;
  category?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface KustomMedia {
  Key: string;
  url: string;
  filename: string;
  metadata: MediaMetadata;
  mutableMetadata: MediaMutableMetadata;
}

export interface KustomResponsiveMedia {
  id: string;
  default?: KustomMedia;
  mobile?: KustomMedia;
  tablet?: KustomMedia;
}

export type KustomResponsiveMediaKey = 'default' | 'mobile' | 'tablet';

export interface AccordionComponentData {
  slides: AccordionComponentSlide[];
}

export interface CarouselComponentSlide {
  id: string;
  title: KustomTranslatedString;
  subtitle: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
  medias: KustomResponsiveMedia;
}

export interface CarouselComponentData {
  slides: CarouselComponentSlide[];
}

export interface CarouselTextImageComponentData {
  slides: CarouselTextImageComponentSlide[];
}

export interface CarouselTextImageComponentSlide {
  id: string;
  title: KustomTranslatedString;
  subtitle: KustomTranslatedString;
  description: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
  medias: {
    id: string;
    default?: KustomMedia;
    tablet?: KustomMedia;
    mobile?: KustomMedia;
  };
}

export interface CustomComponentData {
  atomicComponents: AtomicComponent[];
}

export interface GoogleMapComponentData {
  url: string;
}

export interface InsertComponentSlide {
  id: string;
  title: KustomTranslatedString;
  subtitle: KustomTranslatedString;
  description: KustomTranslatedString;
  url: string;
}

export interface InsertComponentData {
  slides: InsertComponentSlide[];
}

export interface MediasComponentData {
  medias: KustomResponsiveMedia[];
}

export interface OffersListComponentData {
  activeOffersId: string[];
  offers: string[];
}

export interface PanelComponentData {
  id: string;
  openType: string;
  components: KustomPageComponent<any>[];
}

export interface PlanComponentSlide {
  id: string;
  title: KustomTranslatedString;
  distance?: string;
  phone?: string;
  link?: string;
  description: KustomTranslatedString;
  gpsCoordX: string;
  gpsCoordY: string;
  medias: {
    default?: KustomMedia;
    tablet?: KustomMedia;
    mobile?: KustomMedia;
  };
}

export interface PlanComponentData {
  slides: PlanComponentSlide[];
}

export interface RoomBookingComponentData {
  system: string;
  systemData: any;
}

export interface RoomListComponentData {
  activeRoomsId: string[];
  rooms: string[];
}

export interface RoomSpecsComponentData {
  title: KustomTranslatedString;
  peoplesNumber?: number;
  area: string;
  bed: string | KustomTranslatedString;
  feature: string;
}

interface Stuff {
  id: string;
  media?: KustomMedia;
  title: KustomTranslatedString;
}

export interface RoomStuffComponentSlide {
  id: string;
  title: KustomTranslatedString;
  stuff: Stuff[];
}

export interface RoomStuffComponentData {
  slides: RoomStuffComponentSlide[];
}

export interface JobOfferComponentData {
  title: KustomTranslatedString;
  contractType: string;
  workTime: string;
  locationId?: string;
  category?: JobOfferCategory;
  description: KustomTranslatedString;
  buttonLabel: KustomTranslatedString;
  buttonActionType: JobOfferButtonActionType;
  buttonExternalLink?: string;
  buttonEmail?: string;
  atomicComponents: AtomicComponent[];
}

export interface PressArticleComponentData {
  title: KustomTranslatedString;
  date: KustomTranslatedString;
  thumbnail?: KustomResponsiveMedia;
  isExternalLink?: boolean;
  pressArticleLink?: string;
  pressArticleMedia?: KustomResponsiveMedia;
}

export interface BlogPostComponentData {
  title: KustomTranslatedString;
  category: string;
  date?: string;
  author: string;
  content: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
}

export interface TableComponentData {
  nColumns: number;
  nRow: number;
  data: KustomTranslatedString[][];
}

export interface TestimonyComponentSlide {
  id: string;
  title: KustomTranslatedString;
  note?: number;
  author?: string;
  publishDate?: string;
  description: KustomTranslatedString;
  medias: KustomResponsiveMedia;
}

export interface TestimonyComponentData {
  slides: TestimonyComponentSlide[];
}

export interface TextCarouselComponentData {
  textTitle: KustomTranslatedString;
  textSubtitle: KustomTranslatedString;
  text: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
  slides: CarouselComponentSlide[];
}

export interface TextComponentData {
  text: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
}

export interface TextImageComponentData {
  media?: KustomResponsiveMedia;
  text: KustomTranslatedString;
  atomicComponents: AtomicComponent[];
}

export interface JobOfferListComponentData {
  activeItemsId: string[];
  items: string[];
}

export interface BlogPostListComponentData {
  activeItemsId: string[];
  items: string[];
}

export interface PressArticleListComponentData {
  activeItemsId: string[];
  items: string[];
}

export interface BaseAtomicComponent {
  id: string;
  type: 'CODE' | 'TEXT' | 'ACTION_BUTTON' | 'ACCORDION' | 'MEDIA';
  isHidden?: boolean;
  isLocked?: boolean;
}

export interface ActionButtonComponent extends BaseAtomicComponent {
  label: KustomTranslatedString;
  action: string;
  actionString?: string;
  target?: string;
}

export interface AccordionComponent extends BaseAtomicComponent {
  slides: {
    id: string;
    text: KustomTranslatedString;
    hiddenText: KustomTranslatedString;
  }[];
}

export interface AccordionComponentSlide {
  id: string;
  text: KustomTranslatedString;
  hiddenText: KustomTranslatedString;
}

export interface CodeComponent extends BaseAtomicComponent {
  code: string;
}

export interface TextComponent extends BaseAtomicComponent {
  text: KustomTranslatedString;
}

export interface MediaComponent extends BaseAtomicComponent {
  media: KustomResponsiveMedia;
}

export type AtomicComponent =
  | ActionButtonComponent
  | AccordionComponent
  | CodeComponent
  | TextComponent
  | MediaComponent;

export interface KustomPageComponentViewProps<T> {
  component: KustomPageComponent<T>;
  page: KustomPage;
  index?: number;
}

export type JobOfferButtonActionType =
  | 'INTERNAL_FORM'
  | 'EXTERNAL_LINK'
  | 'EMAIL';

export const jobOfferCategories = [
  {
    label: {
      fr: 'Accueil / Réception',
      en: 'Reception',
      es: 'Recepción',
      de: 'Rezeption',
    },
    value: 'reception',
  },
  {
    label: {
      fr: 'Animation',
      en: 'Animation',
      es: 'Animación',
      de: 'Animation',
    },
    value: 'animation',
  },
  {
    label: { fr: 'Baignade', en: 'Bathing', es: 'Baño', de: 'Baden' },
    value: 'bathing',
  },
  {
    label: { fr: 'Commerces', en: 'Shops', es: 'Tiendas', de: 'Geschäfte' },
    value: 'shops',
  },
  {
    label: {
      fr: 'Direction',
      en: 'Management',
      es: 'Dirección',
      de: 'Richtung',
    },
    value: 'management',
  },
  {
    label: {
      fr: 'Sécurité',
      en: 'Security',
      es: 'Seguridad',
      de: 'Sicherheit',
    },
    value: 'security',
  },
  {
    label: {
      fr: 'Services administratifs',
      en: 'Administrative services',
      es: 'Servicios administrativos',
      de: 'Verwaltungsdienste',
    },
    value: 'administrative',
  },
  {
    label: {
      fr: 'Services techniques',
      en: 'Technical services',
      es: 'Servicios técnicos',
      de: 'Technische Dienstleistungen',
    },
    value: 'technical',
  },
  {
    label: {
      fr: 'Snack / Bar / Restauration',
      en: 'Snack / Bar / Restoration',
      es: 'Snack / Bar / Restauracion',
      de: 'Snack / Bar / Catering',
    },
    value: 'catering',
  },
  {
    label: {
      fr: 'Soins & esthétique',
      en: 'Care & beauty',
      es: 'Cuidado y belleza',
      de: 'Pflege & Ästhetik',
    },
    value: 'care',
  },
];

export type JobOfferCategory = (typeof jobOfferCategories)[number]['value'];

export const contractTypes = [
  { label: 'CDI', value: 'permanent' },
  { label: 'CDD', value: 'fixedterm' },
  { label: 'CTT', value: 'temporary' },
];

export const workTimes = [
  {
    label: {
      fr: 'Temps plein',
      en: 'full-time',
      es: 'Tiempo completo',
      de: 'Vollzeit',
    },
    value: 'fulltime',
  },
  {
    label: {
      fr: 'Temps partiel',
      en: 'part-time',
      es: 'Tiempo parcial',
      de: 'Teilzeit',
    },
    value: 'parttime',
  },
];

export interface ExtraFields {
  [key: string]: {
    type: string;
    label: string;
    visibility: boolean;
    value: any;
    options: string[];
  };
}

export interface _Settings {
  _id: string;
  system: {
    environmentType: string;
    debugMode: boolean;
    debugIP: string;
    debugEmail: string;
    defaultLanguage: string;
    displayedLanguages: string;
    mainBookingEngine: string;
    siteLogin: string;
    clientUrl: string;
    extraFields: ExtraFields;
  };
  bookingEngine: {
    loginRates: string;
    passwordRates: string;
    enginePublicLink: string;
    expediaOTACode: string;
    bookingOTACode: string;
    engineSPALink: string;
    extraFields: ExtraFields;
  };
  informations: {
    name: string;
    addressFirstLine: string;
    addressSecondLine: string;
    postalCode: string;
    city: string;
    country: string;
    phone: string;
    mainEmail: string;
    bookingStayEmail: string;
    bookingSPAEmail: string;
    bookingRestaurantEmail: string;
    extraFields: ExtraFields;
  };
  socialNetworks: {
    facebookUrl: string;
    facebookIsActivated: boolean;
    instagramUrl: string;
    instagramIsActivated: boolean;
    instagramUsername: string;
    twitterUrl: string;
    twitterIsActivated: boolean;
    youtubeUrl: string;
    youtubeIsActivated: boolean;
    linkedinUrl: string;
    linkedinIsActivated: boolean;
    pinterestUrl: string;
    pinterestIsActivated: boolean;
    order: string;
    extraFields: ExtraFields;
  };
  modules: {
    hotelSmartPreview: boolean;
    hotelSmartExperience: boolean;
    retargeting: boolean;
    offerHighlight: boolean;
    cloudfare: boolean;
    themeColor: string;
    cloudfareZoneId: string;
    bookingRestaurantWidgetUrl: string;
    availproBigCalendarUrl: string;
    instagramWidgetUrl: string;
    extraFields: ExtraFields;
  };
  googleServices: {
    googleTagManagerHeaderTag: string;
    googleTagManagerBodyTag: string;
    googleMapAPIKey: string;
    googleRecaptchaKey: string;
    googleRecaptchaKeySecret: string;
    extraFields: ExtraFields;
  };
  rgpd: {
    orejime: boolean;
    googleAnalytics: boolean;
    hotjar: boolean;
    linkedinInsight: boolean;
    guestOnline: boolean;
    planity: boolean;
    pinterest: boolean;
    vimeoAPI: boolean;
    mailjetNewsletterRegister: boolean;
    michelinBookatable: boolean;
    hotelPriceExplorer: boolean;
    offerHighlight: boolean;
    qualitelis: boolean;
    whatsappChat: boolean;
    ipCamLive: boolean;
    apidae: boolean;
    stripe: boolean;
    prestashop: boolean;
    recaptcha: boolean;
    wordpressCookies: boolean;
    facebookPixel: boolean;
    sojern: boolean;
    bigCalendar: boolean;
    lafourchette: boolean;
    youtubeVideo: boolean;
    instagramLightWidget: boolean;
    facebookShareWidget: boolean;
    messengerCustomerChat: boolean;
    triptease: boolean;
    customerAlliance: boolean;
    retargeting: boolean;
    tripadvisor: boolean;
    quicktext: boolean;
    openweather: boolean;
    pageproofer: boolean;
    quicksearch: boolean;
    hotelSmartPreview: boolean;
    openStreetMap: boolean;
    extraFields: ExtraFields;
  };
  smtp?: {
    expeditionEmail: string;
    expeditionName: string;
    replyEmail: string;
    replyName: string;
    host: string;
    port: string;
    name: string;
    authenticationMethod: string;
    username: string;
    password: string;
    extraFields: ExtraFields;
  };
  translations?: {
    translations: {
      key: string;
      fr: string;
      en: string;
      es: string;
      de: string;
      // ca: string;
      // it: string;
      // pt: string;
      // ru: string;
      date: Date;
    }[];
    extraFields: ExtraFields;
  };
}
export type KustomSettings = Partial<_Settings>;
