import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import {
  KustomPage,
  News,
  Offer,
  Rubric
} from '@/lib/kustomcms-sdk/lib/types';
import ParallaxElement from '../ParallaxElement';

import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { SvgShape } from '../SvgShape';
import OffersAndNewsCard from './OffersAndNewsCard';
import OffersAndNewsDetails from './OffersAndNewsDetails';

interface OffersAndNewsPageProps {
  page: KustomPage;
}

type bigCategoryType = {
  value: 'OFFERS_AND_NEWS' | 'OFFERS' | 'NEWS';
  active: boolean;
  visible: boolean;
};

type categoryType = {
  value: Rubric;
  active: boolean;
};

const categoriesData: categoryType[] = [
  { value: 'STAY', active: false },
  { value: 'RESTAURANT', active: false },
  { value: 'SPA', active: false },
  { value: 'SEMINARS', active: false },
  { value: 'WEDDINGS', active: false },
  { value: 'WINTER', active: false },
  { value: 'SUMMER', active: false },
  { value: 'AUTUMN', active: false },
  { value: 'SPRING', active: false },

];

const OffersAndNewsPage: React.FC<OffersAndNewsPageProps> = ({ page }) => {
  const { offers, news } = useContext(PagesContext);
  const { currentLang } = useContext(LanguageContext);
  const settingsCtx = useContext(SettingsContext);
  const searchParams = useSearchParams();
  const searchId = searchParams.get('id');
  const searchName = searchParams.get('name');
  const router = useRouter();

  const offersList = offers.filter(
    (o) => o.location?.includes('OFFERS_NEWS_PAGE') && o.status === 'online',
  );
  const newsList = news.filter(
    (n) => n.location?.includes('OFFERS_NEWS_PAGE') && n.status === 'online',
  );

  const getTranslation = (key: string) => {
    return settingsCtx.translations?.[`${key}`]?.[currentLang];
  };

  const bigCategoriesData: bigCategoryType[] = [
    {
      value: 'OFFERS_AND_NEWS',
      active: true,
      visible: offersList.length > 0 && newsList.length > 0,
    },
    { value: 'NEWS', active: false, visible: newsList.length > 0 },
    { value: 'OFFERS', active: false, visible: offersList.length > 0 },
  ];

  const [bigCategories, setBigCategories] =
    useState<bigCategoryType[]>(bigCategoriesData);

  const [categories, setCategories] = useState<categoryType[]>(
    categoriesData.filter((c) =>
      offersList.find((o) => o.rubrics?.includes(c.value)),
    ),
  );

  const isActiveFilter = categories.find((c) => c.active);

  const offersListFiltered = isActiveFilter
    ? offersList.filter((o) =>
      categories.find((c) => o.rubrics?.includes(c.value) && c.active),
    )
    : offersList;

  const activeBigCategory = bigCategories.find((c) => c.active)?.value;
  const offersAndNews =
    activeBigCategory === 'NEWS'
      ? newsList
      : activeBigCategory === 'OFFERS_AND_NEWS'
        ? [...offersList, ...newsList]
        : offersListFiltered;

  offersAndNews.sort((a, b) => {
    if (!a.updatedAt || !b.updatedAt) return 0;
    if (a.updatedAt < b.updatedAt) return 1;
    if (a.updatedAt > b.updatedAt) return -1;
    return 0;
  });

  const [offersOpen, setOffersOpen] = useState<Offer | News | null>(null);

  useEffect(() => {
    const offer = offersAndNews.find(
      (o) => o.prettyURL?.[currentLang] === searchName || o._id === searchId,
    );

    if (offer) {
      setOffersOpen(offer);
    } else {
      setOffersOpen(null);
    }
  }, [searchName, searchId]);

  useEffect(() => {
    document.body.style.overflow = offersOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [offersOpen]);

  if (offersAndNews.length === 0)
    return (
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        px={[6, null, 8, 12, 16]}
      >
        <Heading
          as="h2"
          fontFamily="jhaSemiboldItalic"
          color="brand.500"
          fontSize={['24px', null, '33px', null, null, '42px']}
          textAlign={['center']}
          py={40}
        >
          {getTranslation(`offers_empty_offers_end_news`) || ''}
        </Heading>
      </Container>
    );

  return (
    <>
      {offersOpen && (
        <OffersAndNewsDetails
          offersAndNews={offersOpen}
          onClose={() => {
            // setOffersOpen(null);
            router.push(`${page.prettyUrl[currentLang]}`);
          }}
        />
      )}
      <Box
        bgColor="accent.100"
        py={[8, null, 16, 24, null, 40]}
        overflow="clip"
      >
        <Container
          maxW={['container.xl', null, null, null, null, '1780px']}
          px={[6, null, 8, 12, 16]}
        >
          <Box
            display="flex"
            flexDir={['column', null, null, null, 'row']}
            w="100%"
            gap={['1.5rem', null, null, '3rem', '3rem', '4rem']}
          >
            <Box flex="1" zIndex={1}>
              <Box
                position="sticky"
                top={164}
                py={8}
                display="flex"
                flexDir={['column']}
                justifyContent={'center'}
                gap={['1rem', null, '1.5rem']}
              >
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent={'center'}
                  gap={['3rem', null, null, '3.5rem']}
                >
                  {bigCategories.map((c) => (
                    <Fragment key={c.value}>
                      {c.value !== 'OFFERS_AND_NEWS' && (
                        <OffersAnsNewsTitle
                          key={c.value}
                          text={
                            getTranslation(`offers_ALL_${c.value}`) || c.value
                          }
                          isActive={c.active}
                          isBigCategory={true}
                          onClick={() => {
                            setBigCategories(
                              bigCategories.map((cat) =>
                                cat.value === c.value
                                  ? { ...cat, active: true }
                                  : { ...cat, active: false },
                              ),
                            );
                            setCategories(
                              categories.map((cat) => ({
                                ...cat,
                                active: false,
                              })),
                            );
                          }}
                          value={c.value}
                          visible={c.visible}
                        />
                      )}
                    </Fragment>
                  ))}
                </Box>
                <Box
                  display="flex"
                  flexDir="column"
                  justifyContent={'center'}
                  gap={['0.5rem', null, null, '0.75rem']}
                  borderStyle={'solid'}
                  borderColor="red"
                  position={'relative'}
                >
                  {categories.map((c) => (
                    <OffersAnsNewsTitle
                      key={c.value}
                      text={
                        getTranslation(`offers_offers_${c.value}`) || c.value
                      }
                      isActive={c.active}
                      isBigCategory={false}
                      value={c.value}
                      onClick={() => {
                        setCategories(
                          categories.map((cat) =>
                            cat.value === c.value
                              ? { ...cat, active: true }
                              : { ...cat, active: false },
                          ),
                        );
                        setBigCategories(
                          bigCategories.map((cat) => ({
                            ...cat,
                            active: false,
                          })),
                        );
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box flex="4" position="relative" minH={400}>
              <ParallaxElement
                Element={() => <SvgShape file="illu-algue-1" />}
                top={100}
                right={0}
                width="200px"
              />
              <SimpleGrid columns={[1, null, null, 2]} spacing={10}>
                {offersAndNews.map((o) => (
                  <OffersAndNewsCard
                    key={o._id}
                    offersAndNews={o}
                    onClick={() => {
                      // setOffersOpen(o);
                      if (o.prettyURL?.[currentLang]) {
                        router.push(
                          `${page.prettyUrl[currentLang]}?name=${o.prettyURL?.[currentLang]}`,
                        );
                      } else {
                        router.push(
                          `${page.prettyUrl[currentLang]}?id=${o._id}`,
                        );
                      }
                    }}
                  />
                ))}
              </SimpleGrid>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

interface OffersAnsNewsTitleProps {
  value?: string;
  text: string;
  isActive?: boolean;
  isBigCategory?: boolean;
  visible?: boolean;
  onClick: () => void;
}

const OffersAnsNewsTitle: React.FC<OffersAnsNewsTitleProps> = (props) => {
  const {
    value,
    text,
    isActive,
    isBigCategory,
    onClick,
    visible = true,
  } = props;

  if (isBigCategory && !visible) return null;
  return (
    <Box
      fontFamily="jhaSemibold"
      color="brand.500"
      textAlign={['center', null, null, null, 'unset']}
      fontSize={
        isBigCategory
          ? ['24px', null, null, '26px', null, '32px']
          : ['18px', null, null, '20px', null, '26px']
      }
      letterSpacing={'-0.01em'}
      lineHeight={'1.125'}
      transition="color 0.2s"
      {...(isActive && {
        color: 'brand.100',
      })}
      _groupHover={{
        color: 'brand.100',
      }}
      position={'relative'}
      cursor={'pointer'}
      onClick={onClick}
      sx={
        value == 'OFFERS'
          ? {
            '&:not(:first-child):before': {
              content: '""',
              position: 'absolute',
              top: ['-1.5rem', null, null, null, '-1.75rem'],
              right: ['40%', null, null, null, '0'],
              left: ['40%', null, null, null, '0'],
              bottom: 0,
              opacity: 0.25,
              borderTop: ['1px solid #020d46'],
              pointerEvents: 'none',
            },
          }
          : {}
      }
    >
      {text}
    </Box>
  );
};

export default OffersAndNewsPage;
